import React from "react";
import { Link } from "react-router-dom";
// import Footer from "./Footer";
// import FadeIn from "react-fade-in";
// import git from "./Interactive Minicourse/Gitcourse";
class Hardware extends React.Component {
  render() {
    return (
      <div
        className="main"
        style={{
          overflowY: this.props.disableScroll ? "hidden" : "scroll",
          position: this.props.disableScroll ? "fixed" : "relative",
        }}
      >
        <div className="paddingBottom">
          <div className="rowWithMargin col">
            <h4>
              AVAILABLE HARDWARE
            </h4>
            <h1>
              Borrow the hardware you need
            </h1>
            <br />
            <p className="blackText">
              Come visit our{" "}
              <Link to="/visit" className="lightRedLink">
                tutor desk
              </Link>{" "}
              to reserve or checkout some of our materials!
            </p>
            <p className="blackText">
              Check out our tutor desk's inventory below!
            </p>
            <br />
            <br />
            <div>
              <div className="wrapper--1">
                <input className="hardwareInput" id="tab-1" type="checkbox" />
                <label className="hardwareLabel" for="tab-1">
                  <div>Adapters</div>
                  <div className="cross"></div>
                </label>
                <div className="hardwareTabContent">
                  USBC (Male) to USBA (Female)
                  <br />
                  USBC (Male) to Thunderbolt (Female)
                  <br />
                  USBC (Male) to SD (Female)
                  <br />
                  USBC (Male) to HDMI, USBC, USB3 (Female)
                  <br />
                  USBC (Male) to HDMI (Female)
                  <br />
                  USBA (Male) to Firewire (Female)
                  <br />
                  Thunderbolt (Male) to VGA (Female)
                  <br />
                  Thunderbolt (Male) to Ethernet (Female)
                  <br />
                  Thunderbolt (Male) to Displayport (Female)
                  <br />
                  HDMI (Female) to HDMI (Female)
                </div>
              </div>

              <div className="wrapper--2">
                <input className="hardwareInput" id="tab-2" type="checkbox" />
                <label className="hardwareLabel" for="tab-2">
                  <div>Cables</div>
                  <div className="cross"></div>
                </label>
                <div className="hardwareTabContent">
                  USBC (Male) to USBC (Male)
                  <br />
                  USBC (Male) to Lightning (Male)
                  <br />
                  USBA (Male) to Lightning (Male)
                  <br />
                  USBA (Male) to USBC (Male)
                  <br />
                  USBA (Male) to MicroUSB (Male)
                  <br />
                  USBA (Male) to 30 pin (Male)
                  <br />
                  Thunderbolt (Male) to Thunderbolt (Male)
                  <br />
                  Thunderbolt (Male) to HDMI (Male)
                  <br />
                  HDMI (Male) to HDMI (Male)
                  <br />
                  HDMI (Male) to USBC (Male)
                  <br />
                  USBC (Male) to MagSafe (Male)
                  <br />
                  USBC (Male) to HDMI (Male)
                  <br />
                </div>
              </div>

              <div className="wrapper--4">
                <input className="hardwareInput" id="tab-4" type="checkbox" />
                <label className="hardwareLabel" for="tab-4">
                  <div>Laptop Chargers</div>
                  <div className="cross"></div>
                </label>
                <div className="hardwareTabContent">
                  Dell Round Pin 65W Charger
                  <br />
                  Round Pin Laptop Charger
                  <br />
                  SurfacePro Laptop Charger
                  <br />
                  Macbook Charger 30W USBC
                  <br />
                  Macbook Charger 61W USBC
                  <br />
                  Macbook Charger 87W USBC
                  <br />
                  Macbook Charger 45W Magsafe
                  <br />
                  Macbook Charger 60W Magsafe
                  <br />
                  Macbook Charger 85W Magsafe
                  <br />
                </div>
              </div>
              <div className="wrapper--5">
                <input className="hardwareInput" id="tab-5" type="checkbox" />
                <label className="hardwareLabel" for="tab-5">
                  <div>Phone Chargers</div>
                  <div className="cross"></div>
                </label>
                <div className="hardwareTabContent">
                  Phone Charger USBA
                  <br />
                  Phone Charger USBC
                  <br />
                  Phone Charger Lightning
                  <br />
                  Phone Charger Micro
                </div>
              </div>
              <div className="wrapper--6">
                <input className="hardwareInput" id="tab-6" type="checkbox" />
                <label className="hardwareLabel" for="tab-6">
                  <div>CSIL Lab Specs</div>
                  <div className="cross"></div>
                </label>
                <div className="hardwareTabContent">
                  <b>
                    All Lab comes with mouse and keyboard
                  </b>
                  <br />
                  CSIL 3 - 4 Mac Studios | Mac Studio Displays |
                  <br />
                  CSIL 5 - Dell Precision towers | Dell Monitors |
                  <br />
                  Outboard North - Mac Minis | Mac Studio Displays | Dell Precision towers | Dell Monitors |
                  <br />
                  Outboard South - Mac Minis | Mac Studio Displays | 8 High performance Windows Desktops |
                </div>
              </div>
              <div className="wrapper--7">
                <input className="hardwareInput" id="tab-7" type="checkbox" />
                <label className="hardwareLabel" for="tab-7">
                  <div>Misc.</div>
                  <div className="cross"></div>
                </label>
                <div className="hardwareTabContent">
                  Wired Mice
                  <br />
                  Wireless Mice
                  <br />
                  Senheisser Wired Headphones
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hardware;
